.mobile-navigation {
	display: none;
	opacity: 0;
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100vh;
	transform: translateY(100vh);
	transition: all 500ms ease-in;

	@media screen and (--phone) {
		display: block;
	}
}

.container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	background-color: var(--standard);

	& > .header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding-right: 24px;
	}

	& > .body {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		padding-left: 60px;

		& > li {
			display: flex;
			justify-content: center;
			align-items: flex-start;
			width: 90%;

			& > div {
				width: 50%;
				margin-bottom: 45px;
			}

			&:nth-last-child(1) > div {
				margin-bottom: 0;
			}

			& p {
				color: var(--gray);
				opacity: 0.7;
				font-size: 13px;
				letter-spacing: 1.08px;
				margin-bottom: 5px;
			}

			& a {
				color: var(--white);
				width: 78px;
				font-size: 15px;
				font-weight: 500;
				letter-spacing: 1.5px;
				white-space: nowrap;
			}
		}
	}

	& > .footer {
		padding: 0 25px 25px 25px;

		& > hr {
			height: 0;
			margin: 0;
			border-bottom: 0.5px solid #35499c;
		}

		& .soical-link {
			display: flex;
			margin: 25px 0;
			padding-left: 30px;

			& > a {
				margin-right: 16px;
			}
		}

		& > .address {
			padding-left: 30px;
			font-size: 14px;
			line-height: 2.29;
			letter-spacing: 1px;
			color: var(--white);
		}
	}
}

.logo {
	width: 160px;
	height: 80px;
	background-image: url('../../../images/logo/logo.svg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	transition: background-image 1s ease-in;
}

.mob-lang-switch {
	margin-top: 25px;
}

.hamburger-icon {
	position: relative;
	width: 35px;
	height: 35px;
	outline: none;
	cursor: pointer;

	&::before {
		display: block;
		content: '';
		width: 35px;
		position: absolute;
		top: 0;
		left: 0;
		transform: translate(-1px, 15px) rotate(45deg);
		border-bottom: 1.5px solid #fff;
	}

	&::after {
		display: block;
		content: '';
		width: 35px;
		position: absolute;
		top: 0;
		left: 0;
		transform: translate(-2px, 15px) rotate(-45deg);
		border-bottom: 1.5px solid #fff;
	}
}
