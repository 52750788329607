.navigation {
	& > ul {
		display: flex;
		margin: 0;
		padding: 0;
		list-style: none;
	}

	& li {
		display: flex;
		flex: 1;
		text-align: center;
		justify-content: center;
		align-items: center;
	}

	& a {
		display: inline-block;
		white-space: nowrap;
		padding: 0 15px;
		color: var(--white);
		font-size: 14px;
		font-weight: 500;
		letter-spacing: 1.5px;
		transition: color 1s ease-in;
	}

	@media screen and (--phone) {
		display: none;
	}
}

.theme {
	& a {
		color: var(--standard);
	}
}
