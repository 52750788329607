/*
 * This is for global css usage.
 */

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');

@font-face {
	font-family: 'Optima';
	font-style: normal;
	font-weight: normal;
	src: local('Optima'), url('./assets/fonts/Optima.woff') format('woff');
}

@font-face {
	font-family: 'Optima Italic';
	font-style: normal;
	font-weight: normal;
	src: local('Optima Italic'), url('./assets/fonts/Optima_Italic.woff') format('woff');
}

@font-face {
	font-family: 'Optima Medium';
	font-style: normal;
	font-weight: normal;
	src: local('Optima Medium'), url('./assets/fonts/Optima_Medium.woff') format('woff');
}

@font-face {
	font-family: 'Optima Bold';
	font-style: normal;
	font-weight: normal;
	src: local('Optima Bold'), url('./assets/fonts/Optima_Bold.woff') format('woff');
}

* {
	box-sizing: border-box;
}

body,
input,
select,
textarea,
button {
	font-family: 'Noto Sans TC', 'Noto Sans JP', 'PingFang TC', 'Microsoft JhengHei', sans-serif;
	-webkit-font-smoothing: antialiased;
	outline: none;
	border-radius: 0;
}

body {
	margin: 0;
	overflow-x: hidden;
	width: 100%;
}

ul {
	list-style: none;
	padding: 0;
}

h1 {
	padding: 0;
	margin: 0;
	font-size: 40px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.4;
	letter-spacing: 5px;
	color: var(--standard);
}

h2 {
	padding: 0;
	margin: 0;
	font-size: 36px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.35;
	letter-spacing: 5px;
	color: var(--standard);
}

h3 {
	padding: 0;
	margin: 0;
	font-size: 24px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.75;
	letter-spacing: 2.5px;
	color: var(--standard);
}

h4 {
	padding: 0;
	margin: 0;
	font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: 0.38px;
	text-align: center;
	color: var(--standard);
}

h5 {
	padding: 0;
	margin: 0;
	font-size: 15px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.87;
	letter-spacing: 0.94px;
	color: var(--white);
}

p {
	padding: 0;
	margin: 0;
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 2.13;
	letter-spacing: 1.2px;
	text-align: justify;
	color: var(--darkText);
}

a {
	padding: 0;
	margin: 0;
	text-decoration: none;
	outline: none;
}

button {
	margin: 0;
	padding: 0;
	background-color: transparent;
	border: none;
	outline: none;
	cursor: pointer;
}

textarea,
input {
	appearance: none;
	border-radius: 0;
}

body[lan='tw'] {
	& p[data-element='TimelineCard-content'] {
		letter-spacing: 1.67px;
	}
}

body[lan='en'] {
	& p {
		letter-spacing: 0.25px;
	}

	& a[data-element='nav-link'] {
		letter-spacing: 0.8px;
	}

	& h2[data-element='Business-title'] {
		letter-spacing: 0;
	}

	& p[data-element='KeyCard-contents'] {
		line-height: 1.8;
	}

	& a[data-element='BusinessCard-item'] {
		line-height: 22px;
	}

	& div[data-element='Announcement-section'] p {
		line-height: 2.2;
	}

	& h3[data-element='Paragraph-title'] {
		letter-spacing: 1px;
	}

	& h3[data-element='BusinessCard-title'] {
		letter-spacing: 0.25px;
	}

	& p[data-element='TimelineCard-content'] {
		letter-spacing: 1px;
		text-align: left;
	}
}

body[lan='jp'] {
	& p[data-element='KeyCard-contents'] {
		letter-spacing: 0.3px;
	}

	& div[data-element='Announcement-section'] p {
		font-family: 'Noto sans JP', serif;
	}

	& p[data-element='TimelineCard-content'] {
		letter-spacing: 1.67px;
	}

	& p[data-element='Article-paragraph'] {
		font-family: 'Noto sans JP', serif;
	}
}
