.lang-switch {
	text-transform: uppercase;

	& > .wording {
		padding: 0 7.5px;
		color: var(--white);
		font-size: 14px;
		font-weight: bold;
		font-family: 'Oswald', sans-serif;
		letter-spacing: 1px;
		cursor: pointer;
		opacity: 0.6;
		transition: color 1s ease-in;

		&:focus {
			outline: none;
		}
	}

	&.theme {
		& > .wording {
			color: var(--standard);
		}
	}

	& > .active {
		opacity: 1;
	}

	@media screen and (--phone) {
		margin-right: -50px;
	}
}
