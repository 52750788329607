.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	width: 100%;
	height: 100px;
	padding: 24px 51.5px 24px 0;
	background-color: transparent;
	border-bottom: 1px solid rgba(256, 256, 256, 0.2);
	transition: all 1s ease-in;

	& .logo {
		width: 200px;
		height: 100px;

		@media screen and (--phone) {
			width: 160px;
			height: 80px;
		}
	}

	@media screen and (--phone) {
		height: 80px;
		border-bottom: none;
		padding: 0 24px 0 0;
	}
}

.hamburger-icon {
	display: none;
	width: 32px;
	height: 17px;
	outline: none;
	cursor: pointer;

	& .hamburger-bar {
		display: block;
		width: 32px;
		border-bottom: 1px solid #fff;
		cursor: pointer;
		transition: border 1s ease-in;

		&:nth-child(2) {
			margin: 7px 0;
		}

		&.hamburger-bar-theme {
			border-bottom: 1px solid var(--standard);
		}
	}

	@media screen and (--phone) {
		display: block;
	}
}

.fill {
	background-color: var(--white);
	border-bottom: none;
}

.mob-nav-open {
	opacity: 1;
	transform: translate(0, 0);
}
